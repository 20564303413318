<template>
  <div class="auth__form__content is-size-2" id="auth-login">
    Your Account Has Been Activated
    <div class="auth__form__footer is-flex is-justify-content-center">
      <router-link to="/auth/login" class="is-link is-size-5">
        Login
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        token: this.$route.params.token,
      },
    }
  },

  methods: {
    checkActivateAccountToken() {
      let token = this.form.token
      this.$store
        .dispatch('auth/checkActivateAccountToken', { token })
        .then(() => {
          console.log('success')
        })
        .catch((error) => {
          this.$router.push('/auth/login')
          console.log(error)
        })
    },
  },
  created() {
    if (this.form.token != null) {
      this.checkActivateAccountToken()
    }
  },
}
</script>
